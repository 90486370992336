import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

export const DojoIslandsShareLinkPlayV2 = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { token } = parsedLocation;

  useRedirectHandler(() => {
    if (token) {
      return `https://student.classdojo.com/#/playV2?token=${token}`;
    } else {
      return `https://student.classdojo.com/#/playError`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default DojoIslandsShareLinkPlayV2;
