import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Text } from "@src/components/Text";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import Container from "@src/components/Container";
import SEO from "@src/components/SEO";

const Title = ({ children }) => (
  <Text color="white" fontSize={[16, 24]} marginBottom={0} fontWeight={700} textAlign="center">
    {children}
  </Text>
);

const PageSweepstake = () => {
  const trContext = useContext(TranslationContext);
  const t = (path: string) => trContext.translate(`directus.page_sweepstakes.${path}`);

  const data = useStaticQuery(graphql`
    query {
      directus {
        AI_Transparency {
          Content_HTML
        }
      }
    }
  `);

  return (
    <>
      <SEO title="AI Transparency Note" noindex />
      <Container>
        <div dangerouslySetInnerHTML={{ __html: data.directus.AI_Transparency.Content_HTML }}></div>
      </Container>
    </>
  );
};

export default PageSweepstake;
