// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconArrowRight = ({
  size,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.97378 13.4656H18.0938L13.92 18.5206C13.41 19.1281 13.4325 20.0281 13.965 20.5718C14.2613 20.8756 14.6775 21.0406 15.0975 21.0218C15.5213 21.0068 15.9188 20.8081 16.1925 20.4818L22.05 13.4093C22.7513 12.5731 22.7513 11.3581 22.05 10.5256L16.2675 3.51306C15.9825 3.17181 15.5625 2.97681 15.1163 2.97681C14.7825 2.97681 14.4563 3.08931 14.1938 3.29556C13.8825 3.53931 13.6875 3.89556 13.6463 4.30056C13.6013 4.72806 13.7325 5.16306 14.0025 5.48556L18.0975 10.4731H2.92503C2.50128 10.4731 2.09628 10.6531 1.81128 10.9681C1.52628 11.2831 1.38753 11.7031 1.43253 12.1268C1.50753 12.8806 2.18628 13.4731 2.97753 13.4731L2.97378 13.4656Z" />
    </svg>
  );
};
