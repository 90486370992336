import React, { ReactNode, useContext, useEffect } from "react";
import { Button } from "@src/components/nessie-web";
import styled from "@emotion/styled";
import Container from "@src/components/Container";
import { mediaQueriesMax } from "@src/styles/theme";
import SEO from "@src/components/SEO";
import { graphql, useStaticQuery } from "gatsby";
import { navigateTo } from "@src/utils/location";
import { logEvent } from "@src/utils/logClient";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";

const PageBackground = styled.div`
  padding-block: 60px;
  ${mediaQueriesMax[0]} {
    background-size: 300px;
    background-position: left top;
    padding-bottom: 0;
  }
`;

const Title = styled.h1`
  font-family: "DojoText";
  font-size: 69px;
  text-align: center;
  color: black;
`;

const FrameContainer = styled.div`
  display: flex;
  gap: 36px;
  justify-content: space-evenly;
  margin-top: 60px;
  margin-bottom: 60px;
  flex-direction: row;
  ${mediaQueriesMax[1]} {
    margin-top: 0px;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  ${mediaQueriesMax[2]} {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const FrameStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 33px;
  border-radius: 30px;
  color: white;
  gap: 15px;
  width: 322px;
`;

const FrameTitle = styled.h5`
  font-family: "DojoText";
  font-size: 28px;
  font-weight: 800;
  color: white;
`;

const FrameBody = styled.p`
  font-family: "DojoText";
  font-size: 21px;
  font-weight: 500;
  color: white;
`;

const BottomText = styled.div`
  font-family: "DojoText";
  font-size: 55px;
  color: black;
  text-align: center;
  font-weight: 800;
  letter-spacing: -0.9px;
  position: relative;
  display: inline-block;
  margin: auto;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 36px;
  margin-top: 60px;
  margin-bottom: 60px;
  ${mediaQueriesMax[0]} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

interface FrameProps {
  title: string;
  body: string;
  bgcolor: string;
  rotate: number;
  footer?: ReactNode;
  image?: ReactNode;
}

const Frame = (props: FrameProps) => {
  const { title, body, image, rotate, bgcolor, footer } = props;
  return (
    <FrameStyle style={{ transform: `rotate(${rotate}deg)`, backgroundColor: bgcolor }}>
      <FrameTitle>{title}</FrameTitle>
      <FrameBody>{body}</FrameBody>
      {image}
      {footer}
    </FrameStyle>
  );
};
interface DivImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  image: {
    publicURL: string;
  };
}

const ImageStyled = styled.img`
  ${mediaQueriesMax[0]} {
    position: relative;
  }
  ${mediaQueriesMax[1]} {
    position: relative;
  }
`;

const Image = ({ image, ...rest }: DivImageProps) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <ImageStyled src={image.publicURL} {...rest} />;
};

const TrofyImageStyled = styled.img`
  z-index: -1;
  top: -28px;
  position: absolute;
  width: 202px;
  height: 202px;
  ${mediaQueriesMax[2]} {
    width: 150px;
    height: 150px;
    position: relative;
  }
`;

const SchoolBuildingImageStyled = styled.img`
  z-index: 2000;
  bottom: -25px;
  right: -65px;
  position: absolute;
  width: 220px;
  height: 220px;
  ${mediaQueriesMax[1]} {
    position: relative;
  }
`;

const VideBox = styled.a`
  overflow: hidden;
  border-radius: 18px;
  position: relative;
  flex: 1;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 90;
    height: 90;
    transform: translate(-50%, -50%);
  }
  img {
    display: block;
  }
`;

interface VideSectionProps {
  image: {
    publicURL: string;
  };
  youtubeID: string;
}

const VideoSection = ({ image, youtubeID }: VideSectionProps) => {
  const modalContext = useContext(ModalContext);

  const openVideoModal = () => {
    logEvent({
      eventName: "web.external.iste-2024.vide-play",
      eventValue: window.location.href,
    });
    modalContext.showModal(ModalType.VideoModal, { youtubeID });
  };

  return (
    <VideBox onClick={openVideoModal}>
      <PlayIcon />
      <Image image={image} />
    </VideBox>
  );
};

const PlayIcon = () => (
  <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M87.3092 39.3406C65.996 21.8861 41.7815 8.87771 15.9429 1.01447C10.4322 -0.655256 4.75721 3.03378 3.6806 9.01373C-0.717062 33.1083 -0.717062 57.8823 3.6806 81.9768C4.77546 87.9567 10.4504 91.6651 15.9429 89.9759C41.7997 82.0933 66.0142 69.085 87.3092 51.6499C91.1047 48.5434 91.1047 42.4471 87.3092 39.3406Z"
      fill="white"
    />
  </svg>
);

const ResponsiveContainer = styled(Container)`
  ${mediaQueriesMax[2]} {
    text-align: center;
    padding: 0;
    width: 100%;
  }
`;

const SayHello = () => {
  const data = useStaticQuery(graphql`
    {
      dojoIslands: file(relativePath: { eq: "say-hello/dojo_island_ss.jpg" }) {
        publicURL
      }
      plasticineTrofy: file(relativePath: { eq: "say-hello/plasticine_trofy.png" }) {
        publicURL
      }
      schoolBuilding: file(relativePath: { eq: "say-hello/school_building.png" }) {
        publicURL
      }
      whatsNew: file(relativePath: { eq: "say-hello/what_new_ss.jpg" }) {
        publicURL
      }
      underline: file(relativePath: { eq: "say-hello/underline.svg" }) {
        publicURL
      }
    }
  `);

  useEffect(() => {
    logEvent({
      eventName: "web.external.iste-2024.page-load",
      eventValue: window.location.href,
    });
  }, []);

  const handleGoToSchools = () => {
    logEvent({
      eventName: "web.external.iste-2024.navigate-to-schools",
      eventValue: window.location.href,
    });
    navigateTo("/schools");
  };

  const { dojoIslands, plasticineTrofy, schoolBuilding, whatsNew, underline } = data;

  return (
    <PageBackground>
      <SEO title="Say Hello to Class Dojo" />
      <ResponsiveContainer>
        <Title>Say Hello to Class Dojo!</Title>
        {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> */}
        <TrofyImageStyled src={plasticineTrofy.publicURL} />
        {/* </div> */}
        <FrameContainer>
          <Frame
            title="What’s New"
            body="Learn all about what’s new to ClassDojo (spoiler alert... SO much! 🤩)"
            bgcolor="#64D9A3"
            image={<VideoSection image={whatsNew} youtubeID="nzg_ED_WdRc" />}
            rotate={-1}
          />
          <Frame
            title="Dojo Islands"
            body="Explore a brand new world where students work on teamwork and STEM skills."
            bgcolor="#00B2F7"
            image={<VideoSection image={dojoIslands} youtubeID="FI7n4A0XQdk" />}
            rotate={1}
          />
          <Frame
            title="Invite your School Leader"
            body="ClassDojo is even better when your entire school is onboard. Invite your School Leader to join your
                  community."
            footer={
              <Button onClick={handleGoToSchools} style={{ backgroundColor: "#2C2A50" }}>
                Show me more!
              </Button>
            }
            bgcolor="#F86DC0"
            rotate={-1}
          />
        </FrameContainer>
        <SchoolBuildingImageStyled src={schoolBuilding.publicURL} />
        <Footer>
          <BottomText>
            Zero cost, forever
            <img
              src={underline.publicURL}
              alt="underline"
              style={{ width: "185px", position: "absolute", bottom: -2, right: 1 }}
            />
          </BottomText>
        </Footer>
      </ResponsiveContainer>
    </PageBackground>
  );
};

export default SayHello;
