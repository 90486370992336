import { TypographyTheme } from "@src/components/Typography";
import { colors } from "./colors";
export const typography: TypographyTheme = {
  DisplayOversize: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 92,
    fontWeight: 800,
    lineHeight: "110%",
    letterSpacing: -1.12,
    fontFeatureSettings: `"ss04"`,
  },
  Display0ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 72,
    fontWeight: 800,
    lineHeight: "120%",
    letterSpacing: -1,
    fontFeatureSettings: `"ss04"`,
  },
  Display1ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 69,
    fontWeight: 800,
    lineHeight: 1,
    letterSpacing: -0.9,
    fontFeatureSettings: `"ss04"`,
  },
  Display2ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 55,
    fontWeight: 800,
    lineHeight: "110%",
    letterSpacing: -0.6,
    fontFeatureSettings: `"ss04"`,
  },
  Display3ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 44,
    fontWeight: 800,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Display4ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 35,
    fontWeight: 800,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Display5ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 28,
    fontWeight: 800,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Display6ExtraBold: {
    fontFamily: "DojoDisplay",
    color: colors.cloud80,
    fontSize: 23,
    fontWeight: 800,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Display2Medium: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 55,
    fontWeight: 500,
    lineHeight: "110%",
    letterSpacing: -0.6,
    fontFeatureSettings: `"ss04"`,
  },
  Display3Medium: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 44,
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Display4Medium: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 35,
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Display5Medium: {
    color: colors.cloud80,
    fontFamily: "DojoDisplay",
    fontSize: 35,
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: -0.3,
    fontFeatureSettings: `"ss04"`,
  },
  Headline1ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 28,
    fontWeight: 800,
    lineHeight: 1.25,
    letterSpacing: -0.1,
  },
  Headline2ExtraBold: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 23,
    fontWeight: 800,
    lineHeight: "120%",
    letterSpacing: -0.1,
  },
  Headline3Bold: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "120%",
  },
  Headline1Medium: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 28,
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: -0.1,
  },
  Headline2Medium: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 23,
    fontWeight: 500,
    lineHeight: "120%",
    letterSpacing: -0.1,
  },
  Headline3Medium: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "120%",
  },
  Body1: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 21,
    fontWeight: 500,
    lineHeight: "130%",
  },
  Body2: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "120%",
  },
  Body3: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 15,
    fontWeight: 500,
    lineHeight: "130%",
    letterSpacing: -0.2,
  },
  label: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 15,
    fontWeight: 700,
    lineHeight: "120%",
    letterSpacing: 0.2,
  },
  Label1: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "23px",
  },
  overline: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "120%",
    letterSpacing: 0.7,
    textTransform: "uppercase",
  },
  minutia: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "110%",
    letterSpacing: 0.2,
  },
  MobileHeading: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 35,
    fontWeight: 700,
    lineHeight: "110%",
    letterSpacing: -0.1,
  },
  MobileBody: {
    color: colors.cloud80,
    fontFamily: "DojoText",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "130%",
    letterSpacing: -0.1,
  },
};
