import React from "react";
import styled from "@emotion/styled";
import { Flex } from "@src/components/Boxes";
import theme from "@src/styles/base";
import * as S from "@src/components/partials/districts-2025/styles";
import { IconArrowRight } from "@src/dds-icons";
import { Link } from "gatsby";

interface PartnerWrapperProps {
  image: string;
}

const PartnerWrapper = styled.div<PartnerWrapperProps>`
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  // height: 480px;
  // min-height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  border-radius: ${theme.spacing[30]};
  flex: 1 0 0;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  transition: all 0.2s;
  > div {
    padding: ${theme.spacing[30]};
  }
  z-index: 3;
  background: ${(props) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 62.89%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${props.image}) lightgray 50% / cover no-repeat`};
`;

const PartnerLink = styled(Link)`
  background: rgba(125, 64, 255, 0.12);
  border: solid 1px rgba(125, 64, 255, 0.05);
  width: 100%;
  border-radius: ${theme.spacing[30]};
  position: relative;
  height: 480px;
  transition: all 0.2s;
  &:hover ${PartnerWrapper} {
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4);
      top: 30px;
      left: 30px;
      right: 30px;
      bottom: 60px;
      transition: all 0.2s;
    }
  }
  > span {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
`;

const ExploreLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 60px;
  font-family: "HafferXH";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  left: 60px;
  z-index: 2;
  color: ${theme.colors.contentAccent};
  svg {
    width: 14px;
    path {
      fill: ${theme.colors.contentAccent};
    }
  }
`;

const Body1 = styled.div`
  color: ${theme.colors.contentLight};
`;

const Minutiae = styled.div`
  color: ${theme.colors.contentLight};
`;

interface PartnerCardProps {
  image: string;
  headline: string;
  district: string;
  name: string;
  logo: string;
  link: string;
}

const PartnerCard: React.FC<PartnerCardProps> = ({ link, image, headline, district, name, logo }) => {
  return (
    <PartnerLink to={link}>
      <PartnerWrapper image={image}>
        <div css={{ width: "100%" }}>
          <S.Body1>{headline}</S.Body1>
          <Flex
            css={{
              borderTop: `solid 2px rgba(255, 255, 255, 0.2)`,
              marginTop: theme.spacing[18],
              paddingTop: theme.spacing[18],
            }}
          >
            <div css={{ flexGrow: 1 }}>
              <S.Minutiae css={{ fontWeight: 700 }}>{district}</S.Minutiae>
              <br />
              <S.Minutiae>{name}</S.Minutiae>
            </div>
            <img src={logo} alt="" width={40} />
          </Flex>
        </div>
      </PartnerWrapper>
      <ExploreLink>
        <span>Explore {district}'s story</span>
        <IconArrowRight size="xs" />
      </ExploreLink>
    </PartnerLink>
  );
};

export default PartnerCard;

// <div css={{ background: "red", width: "100%", position: "relative", height: 480 }} key={idx}>
//   <a href="#tobechanged" css={{ position: "absolute", bottom: 20, right: 20, zIndex: 2 }}>
//     Explore
//   </a>
//   <S.Partner
//     css={{
//       zIndex: 3,
//       position: "relative",
//       background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 62.89%, rgba(0, 0, 0, 0.80) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${partner.image}) lightgray 50% / cover no-repeat;`,
//     }}
//   >
//     <div css={{ width: "100%" }}>
//       <S.Body1 css={{ color: theme.colors.contentLight }}>{partner.headline}</S.Body1>
//       <Flex
//         css={{
//           borderTop: `solid 2px rgba(255, 255, 255, 0.2)`,
//           marginTop: theme.spacing[18],
//           paddingTop: theme.spacing[18],
//         }}
//       >
//         <div css={{ color: theme.colors.contentLight, flexGrow: 1 }}>
//           <S.Minutiae>{partner.district}</S.Minutiae>
//           <br />
//           <S.Minutiae>{partner.name}</S.Minutiae>
//         </div>
//         <img src={partner.logo} alt="" width={40} />
//       </Flex>
//     </div>
//   </S.Partner>
// </div>
