import React, { useState, useEffect } from "react";
import { css, Global } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";
import Helmet from "react-helmet";
import Header from "@src/components/Header";
import Footer from "@src/components/Footer";
import Modal from "react-modal";
import normalize from "../styles/normalize";
import theme from "../styles/theme";
import { ModalProvider, ModalRoot } from "@src/components/modals/ModalController";
import { TranslationProvider } from "@src/components/translation/TranslationContext";
import { AppDataContextProvider } from "@src/components/AppDataContext";
import MatomoScript from "@src/components/partials/shared/MatomoScript";
import OneTrustScript from "@src/components/partials/shared/OneTrustScript";
import "modern-normalize/modern-normalize.css";
import { useLocation } from "@reach/router";
import { generatingFor } from "@src/utils/routes";
import StyledLayoutMain from "@src/layouts/common/StyledLayoutMain";
import SkipToContentLink from "@src/components/SkipToContentLink";
import get from "lodash/get";
import TrackingPixels from "@src/components/partials/header/TrackingPixels";
import { DevTools } from "@src/components/devTools";
import { ActivationRedirectionProvider } from "@src/contexts/ActivationRedirectionContext";
import { logEvent } from "@src/utils/logClient";

Modal.setAppElement("#___gatsby");

type PageLayoutProps = {
  children: any;
  pageContext: any;
};
const urlsWithUnpinnedHeader = ["/dojo-islands"];
const urlsWithoutNav = [
  "/teacherlp",
  "/teacherlp2",
  "/teacherlp3",
  "/teacherlp4",
  "/teacherlp5",
  "/teacherlp6",
  "/teacherlp7",
  "/encouragelp",
  "/googleclassroom",
  "/schoolleader2",
  "/student-login-w-parent-help",
  "/homePoints",
  "/homeRewards",
  "/homeMonsters",
  "/homeMonsterCustomizer",
  "/tryitout",
  "/become-mentor-application",
];
const urlsWithoutHeader = ["/ecard", "/beyondschool", "/share-the-news", "/become-mentor-application"];

const urlsWithoutFooter = ["/student-login-w-parent-help", "/share-the-news", "/become-mentor-application"];

const isNavHidden = (pathname: string) => {
  return urlsWithoutNav.some((url) => pathname.endsWith(url));
};

const isHeaderHidden = (pathname: string) => {
  return urlsWithoutHeader.some((url) => pathname.endsWith(url));
};

const isFooterHidden = (pathname: string) => {
  return urlsWithoutFooter.some((url) => pathname.endsWith(url));
};

const isHeaderUnpinned = (pathname: string) => {
  return urlsWithUnpinnedHeader.some((url) => pathname.endsWith(url));
};

const PageLayout: React.FC<PageLayoutProps> = ({ children, pageContext }) => {
  const [, setIsUserInAudience] = useState(false);
  const [navBannerOpen, setNavBannerOpen] = useState<boolean>(false);
  const handleIsUserInAudience = (newValue) => {
    setIsUserInAudience(newValue);
  };

  useEffect(() => {
    const bannerDismissed = localStorage.getItem("bannerDismissed");
    if (bannerDismissed === "true") {
      setNavBannerOpen(false);
    } else {
      setNavBannerOpen(true);
    }
  }, []);

  const lang = get(pageContext, ["localeStrings", "currentLocale"], "en-US");
  generatingFor.locale = lang;
  // const targetCDSLocales = ["en", "en-gb", "pt-br", "es-es", "es-mx", "fr-fr", "fr-ca", "tr-tr"];
  // const isLocaleSupported = targetCDSLocales.includes(lang.toLowerCase());

  const location = useLocation();
  const pathname = location.pathname.endsWith("/") ? location.pathname.slice(0, -1) : location.pathname;

  const closeBanner = () => {
    setNavBannerOpen(false);
    localStorage.setItem("bannerDismissed", "true");
    logEvent("web.external.navbanner.close");
  };
  // const showNavBanner = (pathname === "" || pathname === "/schools") && navBannerOpen;
  const showNavBanner = false;

  const hideNav = isNavHidden(pathname);
  const hideHeader = isHeaderHidden(pathname);
  const hideFooter = isFooterHidden(pathname);
  const unpinHeader = isHeaderUnpinned(pathname);
  const [performanceCookieConsent, setPerformanceCookieConsent] = useState(false);
  const [targetingCookieConsent, setTargetingCookieConsent] = useState(false);
  const [functionalCookieConsent, setFunctionalCookieConsent] = useState(false);

  if (pathname.includes("/ul/") || pathname.includes("/school-approval") || pathname === "/s" || pathname === "/q") {
    return (
      <>
        <TranslationProvider localeStrings={pageContext.localeStrings}>
          <OneTrustScript
            setFunctionalCookieConsent={setFunctionalCookieConsent}
            setPerformanceCookieConsent={setPerformanceCookieConsent}
            setTargetingCookieConsent={setTargetingCookieConsent}
          />
          {children}
        </TranslationProvider>
      </>
    );
  }
  return (
    <>
      <Helmet htmlAttributes={{ lang }}>
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/proxima/proximanova-default-stack.css" />
        <link rel="stylesheet" href="https://static.classdojo.com/fonts/DojoText/DojoText.css" />
      </Helmet>
      <OneTrustScript
        setFunctionalCookieConsent={setFunctionalCookieConsent}
        setPerformanceCookieConsent={setPerformanceCookieConsent}
        setTargetingCookieConsent={setTargetingCookieConsent}
      />
      <Global
        styles={css`
          ${normalize};
          #___gatsby,
          #gatsby-focus-wrapper,
          main,
          html,
          body {
            // height: 100%;
            scroll-behavior: smooth;
          }
          /* modal transitions:  */
          .ReactModal__Content {
            opacity: 0;
            transform: translate(0, -50%);
            transition: transform 300ms ease-out;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translate(0, 0);
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translate(0, -50%);
          }
          body {
            font-family: DojoText, "Helvetica Neue", sans-serif;
          }
          :root {
            interpolate-size: allow-keywords;
          }
        `}
      />
      <TranslationProvider localeStrings={pageContext.localeStrings}>
        <ThemeProvider theme={theme}>
          <AppDataContextProvider
            performanceCookieConsent={performanceCookieConsent}
            targetingCookieConsent={performanceCookieConsent}
            functionalCookieConsent={functionalCookieConsent}
          >
            <ActivationRedirectionProvider>
              <ModalProvider>
                <>
                  <SkipToContentLink />
                  <ModalRoot />
                  <StyledLayoutMain id="main-page-container">
                    {!hideHeader && (
                      <>
                        <>
                          <Header
                            handleIsUserInAudience={handleIsUserInAudience}
                            showNavBanner={showNavBanner}
                            inSchools={pathname === "/schools"}
                            closeBannerFunction={closeBanner}
                            hideNav={hideNav}
                            unpinHeader={unpinHeader}
                          />
                        </>
                      </>
                    )}
                    <div id="page-content" tabIndex={-1}>
                      {children}
                    </div>
                    {!hideFooter && <Footer />}
                    <MatomoScript performanceCookieConsent={performanceCookieConsent} />
                    <TrackingPixels targetingCookieConsent={targetingCookieConsent} />
                    <DevTools />
                  </StyledLayoutMain>
                </>
              </ModalProvider>
            </ActivationRedirectionProvider>
          </AppDataContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </>
  );
};

export default PageLayout;
