import React from "react";
import styled from "@emotion/styled";
import { IconMinus, IconPlus } from "@src/dds-icons";
import { useState } from "react";

const FAQBlock = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 490px;
  align-items: flex-start;
  padding-block: ${({ theme }) => theme.__new.spacing[24]};
  border-bottom: solid 1px ${({ theme }) => theme.__new.colors.bordersDecorative};
  p {
    height: 0;
    opacity: 0;
    display: block;
    transition: all 0.25s;
    transition-behavior: allow-discrete;
    margin: 0;
    &.opened {
      padding-top: ${({ theme }) => theme.__new.spacing[24]};
      height: max-content;
      opacity: 1;
    }
  }
`;

const FAQIcon = styled.div`
  width: 24px;
  min-width: 24px;
  margin-right: 24px;
  height: 24px;
  position: relative;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    path {
      fill: ${({ theme }) => theme.__new.colors.contentAccent}
    }
  }
  transition: transform 1s, opacity: 1s;
  &.opened {
    transform: rotate(360deg);
  }
`;

export const Body2 = styled.p`
  font-family: "HafferXH";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

export const Body2Bold = styled(Body2)`
  font-weight: 700;
`;

export const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <FAQBlock>
      <FAQIcon className={isOpen ? "opened" : "closed"}>
        {isOpen ? <IconMinus className="minus" size="xs" /> : <IconPlus className="plus" size="xs" />}
      </FAQIcon>
      <div>
        <Body2Bold
          onClick={toggleOpen}
          as="h3"
          css={{ display: "block", cursor: "pointer", marginBlock: 0, position: "relative" }}
        >
          {question}
        </Body2Bold>
        <Body2 className={isOpen ? "opened" : ""} dangerouslySetInnerHTML={{ __html: answer }} />{" "}
      </div>
    </FAQBlock>
  );
};
